import { Rest } from 'application/rest'
import { action, observable } from 'mobx'
import UserService from 'stores/userService'


const tokenKey = 'accessToken'
const refreshTokenKey = 'refreshToken'
const error_401 = '401'
const error_403 = '403'

const getDefaultKey = (key) =>
  key ? key : tokenKey

const getToken = (key = null) =>
  window.localStorage.getItem(getDefaultKey(key))

const removeToken = (key = null) =>
  window.localStorage.removeItem(getDefaultKey(key))

const get_401 = () =>
  window.localStorage.getItem(error_401)

const set_401 = (value) =>
  window.localStorage.setItem(error_401, value)

const remove_401 = () =>
  window.localStorage.removeItem(error_401)

const get_403 = () =>
  window.localStorage.getItem(error_403)

const set_403 = (value) =>
  window.localStorage.setItem(error_403, value)

const remove_403 = () =>
  window.localStorage.removeItem(error_403)

const doLogout = async () => {
  try {
    await UserService.doLogout()
  }
  catch (error) {
    console.error(error)
  }
  finally {
    removeToken(tokenKey)
    removeToken(refreshTokenKey)
  }
}

const doGetUserDetail = async () => {
  const rest = new Rest('userschool')
  rest.api = 'ananf/api'
  rest.method = 'authenticated'
  const response = await rest.list()
  return response.json()
}

const doAcceptTermsOfUse = async () => {
  const rest = new Rest('userschool')
  rest.api = 'ananf/api'
  rest.method = 'accept_terms_of_use'
  const response = await rest.list()
  return response.json()
}

const doGetEnablement = async () => {
  const rest = new Rest('user')
  rest.api = 'core/api'
  rest.method = 'enablement'
  const response = await rest.list()
  return await response.json()
}

const doUpdateActiveProfile = async (id, data) => {
  const rest = new Rest('user')
  rest.api = 'core/api'
  rest.detail = id
  rest.method = 'change_active_profile'
  const response = await rest.put(data)
  return await response.json()
}

const doUpdateActiveOrganization = async (active_organization) => {
  const rest = new Rest('user')
  rest.api = 'core/api'
  rest.method = 'change_active_organization'
  const data = {
    'active_organization': active_organization
  }
  const response = await rest.put(data)
  return await response.json()
}

const userStore = observable({
  token: getToken(),
  refreshToken: getToken(refreshTokenKey),
  error: null,
  message: null,
  redirect_after_login: null,
  is_superuser: false,
  active_organization: null,
  user: null,
  name_organization: '',
  auth: {
    id: null,
    email: '',
    username: '',
  },
  profile: {
    name: '',
    email: '',
    profile_name: '',
    profile_id: null,
    profiles: [],
    services: [],
    uuid: '',
    school: '',
    id: null,
  },
  reset: action(function () {
    this.redirect_after_login = null
    this.name_organization = ''
    this.auth = {
      id: null,
      email: '',
      username: '',
      full_name: '',
      terms_accepted: false,
    }
    this.profile = {
      name: '',
      email: '',
      profile: '',
      profile_id: null,
      profiles: [],
      services: [],
      uuid: '',
      school: '',
      preferred_language: '',
    }
    this.token = getToken()
    this.refreshToken = getToken(refreshTokenKey)
    this.message = null
    this.error = null
  }),
  clear: action(function () {
    this.redirect_after_login = null
    this.name_organization = ''
    this.auth = {
      id: null,
      email: '',
      username: '',
      full_name: '',
      terms_accepted: false,
    }
    this.profile = {
      name: '',
      email: '',
      profile: '',
      profile_id: null,
      profiles: [],
      services: [],
      uuid: '',
      school: '',
      preferred_language: '',
    }
    this.token = null
    this.refreshToken = null
    this.message = null
    this.error = null
  }),
  get logged() {
    console.log(!!this.token)
    const logged = !!this.token
    // if (logged && this.profile.name === '') {
    //   this.getUserDetail()
    // }
    return logged
  },
  get authToken() {
    return this.token
  },
  getError: function (field) {
    return this.error !== null && this.error.hasOwnProperty(field) ?
      this.error[field] : null
  },
  doLogout: action(async function () {
    await doLogout()
  }),
  authAfterKeycloak: action(async function () {
    this.message = null
    this.error = null
    try {
      await this.getUserDetail()
      // const dummy = this.logged
    } catch (error) {
      this.message = {
        content: error.detail || "E-mail e senha não conferem!",
        severity: "error"
      }
      this.error = error
    }
  }),
  getUserDetail: action(async function () {
    try {
      const response = await doGetUserDetail()
      this.auth = response
    } catch (error) {
      alert('Usuário sem permissão de acesso.')
      console.error(error.detail)
      UserService.doLogout()
    }
  }),
  acceptTermsOfUse: action(async function () {
    try {
      const that = userStore
      const response = await doAcceptTermsOfUse()
      that.auth = response
    } catch (error) {
      alert('Acesso negado! Aceite os termos de uso.' + error)
      console.error(error.detail)
    }
  }),
  getEnablement: action(async function () {
    try {
      const response = await doGetEnablement()
      this.active_organization = response.active_organization
      this.name_organization = response.name
      this.user = response.user
    } catch (error) { }
  }),
  updateActiveProfile: action(async function () {
    try {
      this.saving = true
      const response = await doUpdateActiveProfile(this.id, this.profile)
      this.profile.profile_name = response.profile
    } catch (error) {
      this.error = error
      this.message = {
        content: "Error updating user active profile!",
        error: true
      }
    } finally {
      this.saving = false
    }
  }),
  updateActiveOrganization: action(async function () {
    try {
      this.saving = true
      const response = await doUpdateActiveOrganization(this.active_organization)
      this.message = {
        content: response.detail || "Atualização realizada com sucesso",
        severity: 'success'
      }
    } catch (error) {
      this.error = error
      this.message = {
        content: error.detail || "Erro ao atualizar a organização!",
        severity: 'error'
      }
    } finally {
      this.saving = false
    }
  }),
  set_401: action(function (message) {
    // Seta 401 no localStorage, afim de apresentar erro após redirecionamento
    set_401(message)

    this.message = {
      content: message,
      error: true
    }
  }),
  remove_401: action(function () {
    // Remove 401 do localStorage
    remove_401()
  }),
  get_401: action(function () {
    // Pega o erro 401 no localStorage, para apresentar mensagem
    const error = get_401()

    if (error) {
      this.message = {
        content: error,
        error: true
      }
    }
  }),
  set_403: action(function (message) {
    // Seta 403 no localStorage, afim de apresentar erro após redirecionamento
    set_403(message)

    this.message = {
      content: message,
      error: true
    }
  }),
  remove_403: action(function () {
    // Remove 403 do localStorage
    remove_403()
  }),
  get_403: action(function () {
    // Pega o erro 403 no localStorage, para apresentar mensagem
    const error = get_403()

    if (error) {
      this.message = {
        content: error,
        error: true
      }
    }
  }),
})

const passwordStore = observable({
  name: '',
  username: '',
  password: '',
  confirm_password: '',
  password_config: '',
  password_changed: '',
  emailSent: false,
  login: '',
  email: '',
  captcha: '',
  token: null,
  error: null,
  loading: false,
  message: null,
  reset: action(function () {
    this.name = ''
    this.username = ''
    this.password = ''
    this.confirm_password = ''
    this.password_config = ''
    this.password_changed = ''
    this.emailSent = false
    this.login = ''
    this.email = ''
    this.captcha = ''
    this.error = null
    this.loading = false
    this.message = null
    this.token = null
  }),
})

export { get_401, get_403, passwordStore, userStore }

