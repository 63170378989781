import { Rest } from 'application/rest'
import { action, observable } from 'mobx'


const doList = async (page = 1, page_size, filter = null) => {
    const rest = new Rest('school')
    rest.api = 'ananf/api'
    rest.query = {
        page,
        page_size,
    }
    if (filter) {
        rest.query.filter = filter
    }
    const response = await rest.list()
    return await response.json()
}

const doGet = async (id) => {
    const rest = new Rest('school')
    rest.api = 'ananf/api'
    const response = await rest.get(id)
    return await response.json()
}

const doCreate = async (data) => {
    const rest = new Rest('school')
    rest.api = 'ananf/api'
    const response = await rest.post(data)
    return await response.json()
}

const doUpdate = async (id, data) => {
    const rest = new Rest('school')
    rest.api = 'ananf/api'
    rest.detail = id
    const response = await rest.put(data)
    return await response.json()
}

const doRemove = async (id) => {
    const rest = new Rest(`school/${id}`)
    rest.api = 'ananf/api'
    const response = await rest.delete()
    return await response.json()
}

const parseError = (error) => {
    let content = error
    if (typeof content === 'object') {
        content = []
        for (let key in error) content.push(error.get(key))
    }
    content = Array.isArray(content) ?
        (<>{content.map((text, i) => <span key={i}>{text}<br /></span>)}</>) :
        String(content)
    return { content, pointing: 'above' }
}

const schools = observable({
    _loading: false,
    request_count: 0,
    list: {
        num_pages: null,
        page: null,
        page_size: null,
        total_size: null,
        results: [],
        filter: null,
        loading: false
    },
    removing: false,
    message: null,
    reset: function () {
        this.list = {
            num_pages: null,
            page: null,
            page_size: null,
            total_size: null,
            results: [],
            filter: null,
            loading: false
        }
        this.loading = false
        this.removing = false
        this.message = null
    },
    get loading() {
        return this._loading || this.request_count > 0
    },
    set loading(value) {
        if (value) {
            this.request_count++
        } else {
            this.request_count--
        }
        this._loading = value
    },
    reload: async function (page = 1) {
        const that = schools
        that.list.loading = true
        await doList(page, that.list.page_size, that.list.filter)
            .then((response) => {
                let filter = that.list.filter
                that.list = response
                that.list.filter = filter
            })
            .catch((error) => {
                console.error(error)
                this.message = {
                    content:
                        error.detail || 'Houve um problema na consulta das escolas.',
                    severity: 'error'
                }
            })
            .finally(() => {
                that.list.loading = false
            })
    },
}, {
    reset: action,
    reload: action,
})

const schoolStore = observable({
    _id: null,
    school: {
        id: null,
        name: '',
        inep: null,
        address: '',
        city: 'Bertioga - SP',
        phone: '',
        email: '',
        type: 'Rede Municipal',
    },
    students: [],
    searching: false,
    _loading: false,
    request_count: 0,
    saving: false,
    message: null,
    error: null,
    reset: function () {
        this._id = null
        this.school = {
            id: null,
            name: '',
            inep: null,
            address: '',
            city: 'Bertioga - SP',
            phone: '',
            email: '',
            type: 'Rede Municipal',
        }
        this.students = []
        this.message = null
        this.error = null
        this.saving = false
        this._loading = false
        this.request_count = 0
    },
    get loading() {
        return this._loading || this.request_count > 0
    },
    set loading(value) {
        if (value) {
            this.request_count++
        } else {
            this.request_count--
        }
        this._loading = value
    },
    getError: function (field) {
        return this.error !== null && this.error.hasOwnProperty(field) ?
            parseError(this.error[field]) : null
    },
    setData: async function (id) {
        this.reset()
        this.loading = true
        if (id) this._id = id
        let that = this
        doGet(id).then(response => {
            that.school = response
        }).catch(error => {
            console.error(error)
        }).finally(() => {
            that.loading = false
        })
    },
    get id() {
        return this._id
    },
    set id(id) {
        this.loading = true
        if (id) this._id = id
        const that = this
        doGet(id)
            .then((response) => {
                that.school = response
            })
            .catch((error) => {
                console.error(error)
                that.message = {
                    content: error.detail?.toString() || 'Houve algum erro ao carregar a escola!',
                    severity: 'error'
                }
            })
            .finally(() => {
                that.loading = false
            })
    },
    save: async function () {
        this.loading = true
        this.saving = true
        const create = this.id === null
        try {
            let response
            if (this.id) {
                response = await doUpdate(this.id, this.school)
            } else {
                response = await doCreate(this.school).then(response => this.id = response.id)
                this.id = response.id
            }
            this.school = response
            this.message = {
                content: create ?
                    response?.detail || "Escola cadastrada com sucesso!" :
                    response?.detail || "Escola atualizada com sucesso!",
                severity: 'success'
            }
            this.error = null
        } catch (error) {
            console.error(error)
            this.error = error
            this.message = {
                content: create ?
                    error.detail || "Erro ao cadastrar a Escola!" :
                    error.detail || "Erro ao atualizar a Escol!",
                severity: 'error',
            }
        } finally {
            this.saving = false
            this.loading = false
        }
    },
    remove: async function (id) {
        this.loading = true
        this.removing = true
        const that = this
        try {
            const response = await doRemove(id)
            that.message = {
                content: response.detail || "Escola excluída com sucesso!",
                severity: 'success'
            }
            that.error = null
        } catch (error) {
            console.error(error)
            that.error = error
            that.message = {
                content: error.detail || "Houve algum erro ao excluir a Escola!",
                severity: 'error'
            }
        } finally {
            that.removing = false
            that.loading = false
        }
    },
}, {
    save: action,
    reset: action,
    setData: action,
})

export { schoolStore, schools }

