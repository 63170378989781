// Libs
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton
} from '@mui/material'
import { observer } from 'mobx-react'
import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

// Icons
import AddCircleIcon from '@mui/icons-material/AddCircle'
import CloseIcon from '@mui/icons-material/Close'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'

// Template
import {
  MDBox,
  MDButton,
  MDTypography
} from '@cloudmatize/cm-core-template/layout/components'
import {
  CardContent,
  Loading,
  PageNavBar,
  PageTemplate
} from '@cloudmatize/cm-core-template/layout/components_custom'
import { DataTable, MessageSnack } from '@cloudmatize/cm-core-template/layout/demos'

// Project
import { CustomButtonEditTable, CustomButtonPendingDetailsTable, CustomButtonRemoveTable } from 'modules/components'
import { schoolStore, schools } from 'modules/school/stores'
import UserService from 'stores/userService'


const FormListSchools = observer(() => {
  const navigate = useNavigate()

  const [openModalRemoveSchool, setOpenModalRemoveSchool] = useState(false)
  const [schoolModal, setSchoolModal] = useState(null)
  const modalRemoveSchool = (school) => {
    setSchoolModal(school)
    setOpenModalRemoveSchool(!openModalRemoveSchool)
  }

  const ModalRemoveSchool = observer(() => {
    return (
      <Dialog
        open={openModalRemoveSchool}
        onClose={modalRemoveSchool}
        fullWidth
        maxWidth={'sm'}
      >
        <DialogTitle>
          <MDTypography variant="h4" textAlign="center">
            Deseja realmente excluir a escola?
            <IconButton
              aria-label="close"
              onClick={modalRemoveSchool}
              color="error"
              sx={{
                float: 'right'
              }}
            >
              <CloseIcon />
            </IconButton>
          </MDTypography>
        </DialogTitle>
        <DialogContent>
          <MDTypography variant="body1" textAlign="center">
            Essa ação irá remover permanentemente a escola:
          </MDTypography>
          <MDTypography variant="body1" textAlign="center">
            {schoolModal?.name}
          </MDTypography>
          <MDTypography variant="body1" textAlign="center">
            Deseja realmente prosseguir?
          </MDTypography>
          <Grid container justifyContent="space-evenly" spacing={4} px={4} mt={-1}>
            <Grid item xs={6}>
              <MDButton
                variant="outlined"
                color="info"
                fullWidth
                size="small"
                onClick={modalRemoveSchool}
              >
                <CloseIcon sx={{ mr: 1 }} />
                Cancelar
              </MDButton>
            </Grid>
            <Grid item xs={6}>
              <MDButton
                variant="gradient"
                color="error"
                fullWidth
                size="small"
                sx={{ opacity: 0.85 }}
                onClick={() => {
                  schoolStore.remove(schoolModal.id).then(() => { schools.reload() })
                  modalRemoveSchool()
                }}
              >
                <DeleteForeverIcon sx={{ mr: 1 }} />
                Excluir
              </MDButton>
            </Grid>
          </Grid>
        </DialogContent>

      </Dialog>
    )
  })

  return (
    <CardContent icon="create">
      <ModalRemoveSchool />
      <Grid container mb={4}>
        <Grid item xs={12} md={6} lg={9}>
          <MDTypography variant="h4">Escolas cadastradas</MDTypography>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          {
            UserService.hasRole([
              process.env.REACT_APP_PROFILE_ADMIN
            ]) &&
            <MDButton
              variant="gradient"
              component={Link}
              to="/nova_escola"
              color="info"
              fullWidth
              size="small"
            >
              <AddCircleIcon sx={{ mr: 1 }} />
              Cadastrar escola
            </MDButton>
          }
        </Grid>
      </Grid>
      <DataTable
        dictPag={schools?.list}
        funcReload={schools.reload}
        canSearch={!!UserService.hasRole([process.env.REACT_APP_PROFILE_ADMIN])}
        table={{
          columns: [
            { Header: 'Inep', accessor: 'inep', width: '15%' },
            { Header: 'Escola', accessor: 'school', width: '25%' },
            { Header: 'Email', accessor: 'email', width: '25%' },
            { Header: 'Telefone', accessor: 'phone', width: '20%' },
            // { Header: 'Endereço', accessor: 'address', width: '25%' },
            { Header: 'Ações', accessor: 'action', width: '15%', align: 'center' }
          ],
          rows: schools.list?.results?.map(function (school) {
            return {
              id: 'list-schools',
              inep: school?.inep,
              school: school?.name,
              email: school?.email,
              phone: school?.phone,
              // address: school?.address,
              action: (
                UserService.hasRole([
                  process.env.REACT_APP_PROFILE_ADMIN
                ]) ?
                  <MDBox>
                    <CustomButtonEditTable onClick={() => navigate(`/editar_escola/${school?.id}`)} helperTitle="Editar dados da escola Teste" />
                    {
                      school?.can_destroy && (
                        <CustomButtonRemoveTable onClick={() => modalRemoveSchool(school)} helperTitle="Excluir escola da plataforma" />
                      )
                    }
                  </MDBox>
                  :
                  <CustomButtonPendingDetailsTable helperTitle="Caso deseja realizar alguma alteração, entre em contato com nosso Suporte" />
              )
            }
          })
        }}
      />
    </CardContent >
  )
})

const ListStudents = observer(() => {
  useEffect(() => {
    schools.reset()
    schoolStore.reset()
    schools.reload()
  }, [])

  if (schools.message) {
    MessageSnack(schools.message)
    schools.message = null
  }

  if (schoolStore.message) {
    MessageSnack(schoolStore.message)
    schoolStore.message = null
  }

  return (
    <PageTemplate header={<PageNavBar titleBk="Escolas" subTitleBk="Escolas cadastradas" />}>
      {schools.loading || schoolStore.loading ? (
        <Loading />
      ) : (
        <FormListSchools />
      )}
    </PageTemplate>
  )
})

export default ListStudents
