// Libs
import { lazily } from 'react-lazily'

// @mui icons
import Icon from "@mui/material/Icon"
import {
  FormListSchools,
  ManagementSchool,
} from 'modules/school/pages'

// Project
const {
  NotAllowed
} = lazily(() => import("modules/default/pages"))

const {
  ManagementAnanf,
  ListAnanf,
  Tracking,
  Progress
} = lazily(() => import("modules/ananf/pages"))

const {
  ListStudents,
  ManagementStudent
} = lazily(() => import("modules/user/pages"))


const _routes = [
  {
    type: "collapse",
    name: "Home",
    key: "/home",
    icon: "hiking",
    route: "/",
    component: <Progress />,
    profiles: [
      process.env.REACT_APP_PROFILE_ADMIN,
      process.env.REACT_APP_PROFILE_SCHOOL,
      process.env.REACT_APP_PROFILE_GUARDIAN,
      process.env.REACT_APP_PROFILE_SUPERVISOR,
    ],
  },
  {
    type: "hidden",
    name: "ANANFs",
    key: "/ananfs",
    icon: "view_list",
    route: "/ananfs/:filter",
    component: <ListAnanf />,
    profiles: [
      process.env.REACT_APP_PROFILE_ADMIN,
      process.env.REACT_APP_PROFILE_SCHOOL,
      process.env.REACT_APP_PROFILE_GUARDIAN,
      process.env.REACT_APP_PROFILE_SUPERVISOR
    ],
  },
  {
    type: "collapse",
    name: "Nova ANANF",
    key: "/nova_ananf",
    icon: "add_circle_outlined",
    route: "/nova_ananf",
    component: <ManagementAnanf />,
    profiles: [
      process.env.REACT_APP_PROFILE_SCHOOL
    ],
  },
  {
    type: "hidden",
    name: "Editar ANANF",
    key: "/editar_ananf",
    icon: "create",
    route: "/editar_ananf/:id",
    component: <ManagementAnanf />,
    profiles: [
      process.env.REACT_APP_PROFILE_ADMIN,
      process.env.REACT_APP_PROFILE_SCHOOL,
      process.env.REACT_APP_PROFILE_GUARDIAN
    ],
  },
  {
    type: "hidden",
    name: "Acompanhamento",
    key: "/acompanhamento",
    icon: "visibility",
    route: "/acompanhamento/:id",
    component: <Tracking />,
    profiles: [
      process.env.REACT_APP_PROFILE_ADMIN,
      process.env.REACT_APP_PROFILE_SCHOOL,
      process.env.REACT_APP_PROFILE_GUARDIAN,
      process.env.REACT_APP_PROFILE_SUPERVISOR
    ],
  },
  {
    type: 'submenu',
    name: 'Seção de Alunos',
    key: '/secao_alunos',
    icon: 'person',
    profiles: [
      process.env.REACT_APP_PROFILE_ADMIN,
      process.env.REACT_APP_PROFILE_SCHOOL
    ],
    children: [
      {
        type: "collapse",
        name: "Novo aluno",
        key: "/novo_aluno",
        icon: "add_circle_outlined",
        route: "/novo_aluno",
        component: <ManagementStudent />,
        profiles: [
          process.env.REACT_APP_PROFILE_ADMIN,
          process.env.REACT_APP_PROFILE_SCHOOL
        ],
      },
      {
        type: "hidden",
        name: "Editar aluno",
        key: "/editar_aluno",
        icon: "create",
        route: "/editar_aluno/:id",
        component: <ManagementStudent />,
        profiles: [
          process.env.REACT_APP_PROFILE_ADMIN,
          process.env.REACT_APP_PROFILE_SCHOOL
        ],
      },
      {
        type: "collapse",
        name: "Alunos cadastrados",
        key: "/alunos",
        icon: "view_list",
        route: "/alunos",
        component: <ListStudents />,
        profiles: [
          process.env.REACT_APP_PROFILE_ADMIN,
          process.env.REACT_APP_PROFILE_SCHOOL
        ],
      },
    ],
  },
  {
    type: 'submenu',
    name: 'Seção de Escolas',
    key: '/secao_escolas',
    icon: 'school',
    profiles: [
      process.env.REACT_APP_PROFILE_ADMIN,
      process.env.REACT_APP_PROFILE_SUPERVISOR
    ],
    children: [
      {
        type: "collapse",
        name: "Nova escola",
        key: "/nova_escola",
        icon: "add_circle_outlined",
        route: "/nova_escola",
        component: <ManagementSchool />,
        profiles: [
          process.env.REACT_APP_PROFILE_ADMIN
        ],
      },
      {
        type: "hidden",
        name: "Editar escola",
        key: "/editar_escola",
        icon: "create",
        route: "/editar_escola/:id",
        component: <ManagementSchool />,
        profiles: [
          process.env.REACT_APP_PROFILE_ADMIN
        ],
      },
      {
        type: "collapse",
        name: "Escolas cadastradas",
        key: "/escolas",
        icon: "view_list",
        route: "/escolas",
        component: <FormListSchools />,
        profiles: [
          process.env.REACT_APP_PROFILE_ADMIN,
          process.env.REACT_APP_PROFILE_SUPERVISOR
        ],
      },
    ]
  },
  {
    type: "hidden",
    name: "Sem acesso",
    key: "not_allowed",
    icon: <Icon fontSize="small">visibility</Icon>,
    route: "/not-allowed",
    component: <NotAllowed />,
  },
]

export default _routes
