// Libs
import { observer } from 'mobx-react'
import { useEffect, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

// Icons
import SaveIcon from '@mui/icons-material/Save'

// Template
import {
  CardContent,
  Loading,
  PageNavBar,
  PageTemplate
} from '@cloudmatize/cm-core-template/layout/components_custom'
import {
  MessageSnack
} from '@cloudmatize/cm-core-template/layout/demos'

// Project
import { MDButton, MDInput, MDTypography } from '@cloudmatize/cm-core-template/layout/components'
import { Grid } from '@mui/material'
import { schoolStore } from 'modules/school/stores'

const FormSchool = observer(() => {
  const { id } = useParams()
  const formRef = useRef()
  const navigate = useNavigate()

  const handleChangeDataSchool = (event) => {
    schoolStore.school[event.target.name] = event.target.value
  }

  const handleSave = () => {
    if (formRef.current.reportValidity()) {
      schoolStore.save().then(() => {
        if (schoolStore.error === null && !id) {
          navigate(`/editar_escola/${schoolStore.id || id}`)
        }
      })
    }
  }

  return (
    <CardContent icon="create" goBack>
      <form ref={formRef}>
        <MDTypography variant="h4" pb={1}>
          {id ? "Atualizar informações da escola" : "Formulário para cadastro da escola"}
        </MDTypography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={6}>
            <MDInput
              id="outlined-basic-school-name"
              name="name"
              inputProps={{ group: 'school' }}
              label="Nome da Escola"
              placeholder="Nome da Escola"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              value={schoolStore.school.name}
              onChange={handleChangeDataSchool}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <MDInput
              id="outlined-basic-school-inep"
              name="inep"
              inputProps={{ group: 'school' }}
              label="Inep"
              type="number"
              placeholder="Registro Inep da Escola"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              value={schoolStore.school.inep}
              onChange={handleChangeDataSchool}
              fullWidth
              required
              disabled={id ? true : false}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <MDInput
              id="outlined-basic-school-address"
              name="address"
              inputProps={{ group: 'school' }}
              label="Endereço"
              placeholder="Endereço"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              value={schoolStore.school.address}
              onChange={handleChangeDataSchool}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <MDInput
              id="outlined-basic-school-phone"
              name="phone"
              inputProps={{ group: 'school' }}
              label="Telefone"
              placeholder="Telefone"
              type="telNo"
              pattern="[+]{1}[0-9]{11,14}"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              value={schoolStore.school.phone}
              onChange={handleChangeDataSchool}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <MDInput
              id="outlined-basic-email"
              name="email"
              label="E-mail"
              placeholder="E-mail"
              type="email"
              variant="outlined"
              inputProps={{ group: 'school' }}
              InputLabelProps={{ shrink: true }}
              value={schoolStore.school.email}
              onChange={handleChangeDataSchool}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <MDInput
              id="outlined-basic-school-city"
              name="city"
              inputProps={{ group: 'school' }}
              label="Município"
              placeholder="Município"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              // value={schoolStore.school.city}
              value="Bertioga - SP"
              onChange={handleChangeDataSchool}
              fullWidth
              required
              disabled
            />
          </Grid>
          <Grid item xs={12} md={12} lg={6}>
            <Grid item xs={12}>
              <MDTypography variant="body2" mt={1.25} textAlign="center">
                Escola Municipal
              </MDTypography>
            </Grid>
          </Grid>
          <Grid container justifyContent="flex-end" ml={2} mt={2}>
            <Grid item xs={12} md={6} lg={3}>
              <MDButton
                variant="gradient"
                color="info"
                flex
                flexDirection={'row'}
                onClick={handleSave}
                fullWidth
                sx={{ opacity: 0.8 }}
                size="small"
              >
                <SaveIcon sx={{ mr: 1 }} />
                {id ? 'Atualizar informações' : 'Gravar informações'}
              </MDButton>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </CardContent>
  )
})

const ManagementSchools = observer(() => {
  const { id } = useParams()

  useEffect(() => {
    schoolStore.reset()
    if (id) {
      schoolStore.id = id
    }
  }, [id])

  if (schoolStore.message) {
    MessageSnack(schoolStore.message)
    schoolStore.message = null
  }

  return (
    <PageTemplate header={<PageNavBar titleBk="Gestão de escolas" subTitleBk={id ? "Editar informações da escola" : "Cadastrar nova escola"} />}>
      {schoolStore.loading ? <Loading /> : <FormSchool />}
    </PageTemplate>
  )
})

export default ManagementSchools
