// Libs
import Keycloak from "keycloak-js"

// Project
import { userStore } from "stores/user"


const accessTokenKey = 'accessToken'
const refreshTokenKey = 'refreshToken'
const REACT_APP_TIME_SEC_EXPIRED_ACCESS = 300


const getToken = () => window.localStorage.getItem(accessTokenKey)
const setToken = (tokenKey, newToken) => window.localStorage.setItem(tokenKey, newToken)

// -------------------------------- KEYCLOAK --------------------------------
const _kc = new Keycloak('/keycloak.json')

const initKeycloak = (onAuthenticatedCallback) => {
  const options = {
    onLoad: 'login-required',
  }

  _kc.init(options)
    .then(async (authenticated) => {
      if (authenticated) {
        setToken(accessTokenKey, _kc.token)
        setToken(refreshTokenKey, _kc.refreshToken)
        await userStore.authAfterKeycloak()
      }
      onAuthenticatedCallback()
    })
}

_kc.onTokenExpired = () => {
  _kc.updateToken(REACT_APP_TIME_SEC_EXPIRED_ACCESS).then(() => {
    setToken(accessTokenKey, _kc.token)
    setToken(refreshTokenKey, _kc.refreshToken)
  }).catch((e) => console.error("Erro ao renovar o token ", e))
}

const getListRolesUserKeycloak = () => {
  if (_kc.resourceAccess[_kc.clientId] !== undefined && Object.hasOwn(_kc.resourceAccess[_kc.clientId], 'roles')) {
    return _kc.resourceAccess[_kc.clientId].roles.join(', ')
  }
  return "SEM PERFIL"
}

const doLoginKeycloak = _kc.login

const updateTokenKeycloak = (successCallback) =>
  _kc.updateToken(REACT_APP_TIME_SEC_EXPIRED_ACCESS)
    .then(successCallback)
    .catch(doLoginKeycloak)

const redirectForgotCredentialsKeycloak = () => window.location = _kc.createLoginUrl().replace('auth?', 'forgot-credentials?ui_locales=pt-BR&')

const doLogout = () => {
  if (_kc.authenticated) _kc.logout().then(() => _kc.createLogoutUrl().then((logout_url) => document.location.href = logout_url))
}

const isLoggedIn = () => !!_kc.token || userStore.logged

const getUsername = () => _kc.tokenParsed?.preferred_username

const hasRole = (profiles) => {
  if (profiles?.length > 0) {
    // let temp = profiles
    // temp.push(process.env.REACT_APP_PROFILE_ADMIN)
    // temp = new Set(profiles)
    // console.log(temp)
    // var _profiles = new Set(temp)
    // let has = false
    // for (let i = 0; i < profiles.length; i++) {
    //   if (_profiles.has(profiles[i])) {
    //     has = true
    //     break
    //   }
    // }
    // return has
    // return _profiles.filter((profile) => _kc.hasResourceRole(profile)).length > 0 ? true : false
    return profiles.filter((profile) => _kc.hasResourceRole(profile)).length > 0 ? true : false
  }
  return true
}

const UserService = {
  initKeycloak,
  doLogout,
  isLoggedIn,
  getListRolesUserKeycloak,
  getToken,
  updateTokenKeycloak,
  getUsername,
  hasRole,
  redirectForgotCredentialsKeycloak,
}

export default UserService