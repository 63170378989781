// Libs
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import { observer } from 'mobx-react'
import { Suspense, useEffect } from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'

// Images
import logo_app from 'assets/images/logo_app.png'

// Template
import { Loading } from '@cloudmatize/cm-core-template/layout/components_custom'
import { useMaterialUIController } from '@cloudmatize/cm-core-template/layout/context'
import {
  Sidenav
} from '@cloudmatize/cm-core-template/layout/demos'
import theme from '@cloudmatize/cm-core-template/themes/theme'
import themeDark from '@cloudmatize/cm-core-template/themes/theme-dark'

// Project
import { Tooltip } from '@mui/material'
import { TermsOfUse } from 'modules/default/pages'
import routes from 'routes'
import { mainStore } from 'stores'
import { userStore } from 'stores/user'
import UserService from 'stores/userService'

// eslint-disable-next-line
const { version: appVersion } = require('../package.json')

const App = observer(() => {
  const [controller] = useMaterialUIController()
  const { direction, layout, sidenavColor, darkMode } =
    controller

  const { pathname } = useLocation()

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute('dir', direction)
  }, [direction])

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0
    document.scrollingElement.scrollTop = 0
  }, [pathname])

  useEffect(() => {
    mainStore.getProfileNameUser()
  }, [])

  const routesSidenav = (allRoutes) =>
    allRoutes
      .filter((route) => UserService.hasRole(route.profiles))
      .filter((route) => pathname.includes("/nova_ananf") ? !route.key.includes("/nova_ananf") : route)
      .filter((route) => pathname.includes("/editar_ananf") ? !route.key.includes("/nova_ananf") : route)
      .map((route) => {
        if (route?.type === 'submenu') {
          return {
            ...route,
            children: routesSidenav(route?.children),
          };
        }
        return route
      })
      .filter((route) => route?.type !== 'submenu' || route?.children.length > 0)

  const getRoutes = (allRoutes) =>
    allRoutes
      .filter((route) => UserService.hasRole(route.profiles))
      .map((route) => {
        if (route.type === 'submenu') {
          return getRoutes(route?.children)
        }

        if (route?.route) {
          return (
            <Route
              exact
              path={route?.route}
              element={route?.component}
              key={route?.key}
            />
          )
        }
        return null
      })

  return (
    <Suspense fallback={<Loading />}>
      {routes.length === 0 ? (
        <Loading />
      ) : (
        <ThemeProvider theme={darkMode ? themeDark : theme}>
          <>
            {
              userStore.auth.terms_accepted ? (
                <>
                  <CssBaseline />
                  {layout === 'dashboard' && (
                    <Sidenav
                      color={sidenavColor}
                      brand={logo_app}
                      brandName={
                        <Tooltip
                          title={
                            `${userStore.auth?.full_name ?? ""}`
                          }
                          placement="top">
                          ANANF - {appVersion}
                        </Tooltip>
                      }
                      routes={routesSidenav(routes)}
                      funLogout={async () => userStore.doLogout()}
                    />
                  )}
                  <Routes>
                    {getRoutes(routes)}
                    <Route path="*" element={<Navigate to="/not-allowed" />} />
                  </Routes>
                </>
              ) : (
                <TermsOfUse funAcceptTerms={userStore.acceptTermsOfUse} />
              )
            }
          </>
        </ThemeProvider>
      )}
    </Suspense>
  )
})

export { App }

