// Libs
import { IconButton, styled } from '@mui/material'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import { observer } from 'mobx-react'

// Icons
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ContactsIcon from '@mui/icons-material/Contacts'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import EditIcon from '@mui/icons-material/Edit'
import VisibilityIcon from '@mui/icons-material/Visibility'


const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        fontSize: 12
    }
}))


const CustomButtonEditTable = observer(({ onClick, helperPacament = "left", helperTitle = "Editar" }) => {
    return (
        <HtmlTooltip
            placement={helperPacament}
            title={helperTitle}
        >
            <IconButton
                onClick={onClick}
                sx={{ backgroundColor: "#3181af1d", mx: 0.25, my: -2.25 }}
            >
                <EditIcon fontSize="small" color="info" />
            </IconButton>
        </HtmlTooltip>
    )
})


const CustomButtonRemoveTable = observer(({ onClick, helperPacament = "left", helperTitle = "Excluir" }) => {
    return (
        <HtmlTooltip
            placement={helperPacament}
            title={helperTitle}
        >
            <IconButton
                onClick={onClick}
                sx={{ backgroundColor: "#ff000013", mx: 0.25, my: -2.25 }}
            >
                <DeleteForeverIcon fontSize="small" color="error" />
            </IconButton>
        </HtmlTooltip>
    )
})

const CustomButtonSelectTable = observer(({ onClick, helperPacament = "left", helperTitle = "Selecionar" }) => {
    return (
        <HtmlTooltip
            placement={helperPacament}
            title={helperTitle}
        >
            <IconButton
                onClick={onClick}
                sx={{ border: "1px solid #b7dfc63c", backgroundColor: "#00ff4010", mx: 0.25, my: -2.25 }}
            >
                <CheckCircleIcon fontSize="small" color="success" sx={{ opacity: 0.85 }} />
            </IconButton>
        </HtmlTooltip>
    )
})

const CustomButtonSeeDetailsTable = observer(({ onClick, helperPacament = "left", helperTitle = "Visualizar", status = 0 }) => {
    let theme = {
        1: {
            border: "1px solid #9bbbf739",
            backgroundColor: "#5b94ff10",
            color: "info"
        },
        2: {
            border: "1px solid #e79bf73a",
            backgroundColor: "#d05bff10",
            color: "primary"
        },
        8: {
            border: "1px solid #78af8621",
            backgroundColor: "#00ff4010",
            color: "success"
        },
        0: {
            border: "1px solid #dac08939",
            backgroundColor: "#ffcb5b24",
            color: "warning"
        }
    }
    theme = theme[status.toString().charAt(0)] ?? theme[0]

    return (
        <HtmlTooltip
            placement={helperPacament}
            title={helperTitle}
        >
            <IconButton
                onClick={onClick}
                sx={{ border: theme.border, backgroundColor: theme.backgroundColor, mx: 0.25, my: -2.25 }}
            >
                <VisibilityIcon fontSize="small" color={theme.color} sx={{ opacity: 0.85 }} />
            </IconButton>
        </HtmlTooltip>
    )
})

const CustomButtonPendingDetailsTable = observer(({ onClick, helperPacament = "left", helperTitle = "Visualizar" }) => {
    return (
        <HtmlTooltip
            placement={helperPacament}
            title={helperTitle}
        >
            <IconButton
                onClick={onClick}
                sx={{ border: "1px solid #9bbbf739", backgroundColor: "#5b94ff10", mx: 0.25, my: -2.25 }}
            >
                <ContactsIcon fontSize="small" color="info" sx={{ opacity: 0.85 }} />
            </IconButton>
        </HtmlTooltip>
    )
})


export {
    CustomButtonEditTable,
    CustomButtonPendingDetailsTable,
    CustomButtonRemoveTable,
    CustomButtonSeeDetailsTable,
    CustomButtonSelectTable
}

