// Libs
import { observer } from 'mobx-react'
import { useEffect, useRef, useState } from 'react'

// Icons
import CancelIcon from '@mui/icons-material/Cancel'
import DoneAllIcon from '@mui/icons-material/DoneAll'

// Template
import { MDBox, MDButton, MDTypography } from '@cloudmatize/cm-core-template/layout/components'
import { Tooltip } from '@mui/material'
import BackgroundImage from 'assets/images/background-terms-use.jpg'

// Project
import { userStore } from 'stores/user'


const TermsOfUse = observer(({ funAcceptTerms }) => {
  const [disableButton, setDisableButton] = useState(false)

  const divScroll = useRef(null)

  const handleScroll = () => {
    if (divScroll.current) {
      const { scrollTop, scrollHeight, clientHeight } = divScroll.current
      setDisableButton((scrollTop + clientHeight) >= 0.90 * scrollHeight)
    }
  }

  useEffect(() => {
    const div = divScroll.current
    if (div) {
      div.addEventListener('scroll', handleScroll)
      return () => {
        div.removeEventListener('scroll', handleScroll)
      }
    }
  }, [divScroll])


  return (
    <MDBox
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100vw",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        display: "flex",
        backgroundImage: `url(${BackgroundImage})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'repeat'
      }}
    >
      <MDBox
        margin="0 auto"
        sx={{
          width: { sm: "97%", md: "90%", lg: "50%" },
          backgroundColor: "#ffffffb0",
          borderRadius: "10px",
          m: 5
        }}
      >
        <MDBox mx={4} my={2}>
          <MDTypography align="center" variant="h4">
            TERMOS DE USO E POLÍTICA DE PRIVACIDADE ANANF DIGITAL
          </MDTypography>

          <MDBox id="items-terms" ref={divScroll} overflow="auto" maxHeight="550px" mt={1}>
            <MDTypography sx={{ whiteSpace: "pre-line" }} variant="body1" fontSize="0.8rem">
              {` 
                            1. DEFINIÇÃO
                                1.1. O Sistema ANANF Digital é a base da ferramenta tecnológica disponibilizada aos Gestores Municipais da Secretaria de Educação e ao Conselho Tutelar, que objetiva auxiliar a identificação, o mapeamento e a gestão de casos de crianças e adolescentes, em idade escolar obrigatória, que estão fora da escola no âmbito da administração municipal.
                                1.2. O Sistema ANANF Digital, neste Termo, passa a ser denominado apenas por ANANF Digital.

                            2. CIÊNCIA, CONCORDÂNCIA E COMPROMISSO
                                2.1. Ao acessar a ANANF Digital, os gestores municipais e o conselho tutelar declaram ter ciência e concordam com o uso da ferramenta tecnológica para identificar, mapear e fazer a gestão dos casos de crianças e adolescentes que estão fora da escola no âmbito de responsabilidade da gestão escolar.
                                2.2. O acesso à ANANF Digital será franqueado apenas para profissionais que possuam vínculo com a Administração Pública Municipal e que atuem na gestão das políticas públicas setoriais necessárias ao enfrentamento da exclusão escolar.
                                2.3. Caso haja informações que indiquem o acesso à ANANF Digital por funcionário não autorizado, manifesta-se a concordância de que verificações e bloqueios sejam realizados pela administração responsável com a finalidade de preservar aspectos ligados ao sigilo e à segurança dos dados.
                                2.4. A Administração Pública Municipal compromete-se a disponibilizar que o acesso à ANANF Digital seja realizado de maneira satisfatória pelos funcionários designados.
                                2.5. Em nenhuma circunstância, as organizações gestoras da ANANF Digital serão responsabilizadas por: (I) qualquer dano direto, indireto, incidental, especial, punitivo ou imprevisto resultante de quaisquer erros, equívocos, imprecisões ou mau uso; (II) danos pessoais ou materiais, de qualquer natureza, resultante do acesso indevido por qualquer usuário; (III) danos causados por bugs, vírus, malwares, worms, cavalos-de-tróia ou afins que possam ser transmitidos por meio dos equipamentos; e/ou (IV) quaisquer erros ou omissões de qualquer natureza que resultem em perda ou dano em consequência do uso de qualquer conteúdo ou e-mail enviado, transmitido ou de qualquer outra forma, seja por responsabilidade, contrato, ofensa ou qualquer outra hipótese legal.
                                2.6. A Secretaria de Educação responsável pelo gerenciamento do Sistema ANANF Digital poderá, a seu exclusivo critério, e em qualquer tempo, modificá-la ou desativá-la, bem como limitar, cancelar ou suspender seu uso ou o acesso, sem necessidade de qualquer aviso prévio.

                            3. USO E NOTIFICAÇÃO DE VIOLAÇÃO DE DIREITOS
                                3.1. Os usuários do Sistema ANANF Digital assumem o compromisso de: (I) utilizá-la apenas para finalidades lícitas; (II) não publicar, enviar, distribuir ou divulgar conteúdos de caráter difamatório, obsceno ou ilícito, inclusive informações de propriedade exclusiva pertencente a outras pessoas ou empresas, bem como produtos ou marcas registradas ou informações protegidas por direitos autorais, sem a expressa autorização da Secretaria Municipal de Educação de Bertioga; e (III) denunciar, pelos meios disponibilizados pelos administradores do sistema, qualquer tipo de violação que venha a ocorrer.

                            4. DISPOSIÇÕES GERAIS
                                4.1. Ao aceitar este termo, o usuário concorda com as disposições acima.
                                4.2. Se qualquer cláusula deste Termo for considerada inválida pela autoridade competente, a invalididade de tal cláusula não afetará a validade das cláusulas restantes, que permanecerão integralmente válidas e em vigor.
                                4.3. Qualquer renúncia tácita, neste Termo, não será considerada renúncia adicional ou contínua a esse ou a qualquer outro termo, e a omissão por parte das organizações gestoras da ANANF Digital de não executar qualquer direito ou cláusula de acordo com este Termo não constituirá renúncia a tal direito ou cláusula.
                                4.4. Este Termo e as relações existentes entre a Administração Municipal, as organizações gestoras do sistema são regidos pelas leis da República Federativa do Brasil, sem consideração a qualquer disposição sobre conflito.
                                4.5. Este Termo poderá ser alterado a qualquer tempo, sem qualquer aviso, comprometendo-se os usuários designados pela Administração Municipal, a Administração Estadual e as organizações gestoras a acessar regularmente o documento e consultar o texto vigente.
                                4.6. As informações declaradas e imputadas no âmbito da ANANF Digital são de inteira e exclusiva responsabilidade dos prepostos designados pela Administração Municipal e as organizações gestoras para operá-la.

                            LEGISLAÇÃO VIGENTE
                                Lei Geral de Proteção de Dados Pessoais (LGPD) - Lei no 13.709/2018

                            DEFINIÇÃO E FINALIDADE
                                A ANANF Digital visa identificar, mapear e gerenciar casos de crianças e adolescentes em idade escolar fora da escola. Em conformidade com a LGPD, é essencial garantir a proteção dos dados pessoais utilizados no sistema.

                            TRATAMENTO DE DADOS PESSOAIS
                                Base Legal: Conforme a LGPD, o tratamento de dados pessoais deve ter uma base legal, como o cumprimento de obrigações legais ou regulatórias pela administração pública (art. 7o, inciso II).
                                Finalidade: Os dados devem ser tratados exclusivamente para determinadas finalidades específicas (art. 6o, inciso I).
                                Adequação: Os dados coletados devem ser compatíveis com as finalidades informadas ao titular (art. 6o, inciso II).
                                Necessidade: Apenas os dados essenciais para o cumprimento das finalidades devem ser tratados (art. 6o, inciso III).
                                Direitos dos Titulares dos Dados
                                Acesso e Correção: Os titulares têm o direito de acessar e corrigir seus dados pessoais (art.18, incisos I e III).
                                Anonimização, Bloqueio ou Eliminação: Os titulares podem solicitar a anonimização, bloqueio ou eliminação de dados desnecessários ou tratados em desconformidade com a LGPD (art. 18, inciso IV).

                            BUSCA ATIVA ESCOLAR
                                Lei no 13.005/2014 - Plano Nacional de Educação (PNE)

                            POLÍTICA DE BUSCA ATIVA
                                Objetivo: O PNE estabelece a necessidade de estratégias de busca ativa para garantir que todas as crianças e adolescentes estejam matriculados e frequentando a escola (art. 2o, incisos IV e V).
                                Medidas de Segurança: A ANANF Digital deve adotar medidas de segurança técnicas e administrativas para proteger os dados pessoais de acessos não autorizados e situações acidentais ou ilícitas de destruição, perda, alteração, comunicação ou difusão (art. 6o, inciso VII).
                                Notificação: Em caso de incidentes de segurança que possam acarretar risco ou dano relevante aos titulares, a ANANF Digital deverá comunicar à Autoridade Nacional de Proteção de Dados (ANPD) e aos próprios titulares dos dados afetados (art. 48).

                            COMPARTILHAMENTO DE DADOS
                                Finalidade Específica: O compartilhamento de dados com outras entidades deve ter uma finalidade específica e ser comunicado aos titulares (art. 7o, inciso V).
                                Consentimento: Quando aplicável, deve-se obter o consentimento dos titulares para o compartilhamento de dados, a menos que haja outra base legal adequada (art. 7o, inciso VII).


                            SECRETARIA DE EDUCAÇÃO
                            DEPARTAMENTO DE GESTÃO DE TECNOLOGIA EDUCACIONAL
                            `.split('\n').map((line, i) =>
                <MDTypography key={i} variant="body1" fontSize="0.85rem" >
                  {line ? line : '\n'}
                </MDTypography>
              )}

            </MDTypography>
          </MDBox>

          <MDTypography textAlign="center" variant="body1" fontSize="1.1rem" mt={3}>
            Ao clicar em "Aceitar", você confirma a leitura e ciência de todos os termos e políticas descritas acima.
          </MDTypography>

          <MDBox width="100%" display="flex" justifyContent="space-evenly">
            <MDButton
              onClick={async () => userStore.doLogout()}
              variant="outlined"
              color="primary"
              sx={{ mt: 1, width: 250 }}
            >
              <CancelIcon sx={{ mr: 1 }} />
              Recusar
            </MDButton>
            <Tooltip title={disableButton ? "" : "Leia todos os termos de uso para aceitar"}>
              <MDBox sx={{ opacity: disableButton ? 1 : 0.5 }}>
                <MDButton
                  onClick={() => funAcceptTerms()}
                  variant="gradient"
                  color="info"
                  sx={{ mt: 1, width: 250 }}
                  disabled={!disableButton}
                >
                  <DoneAllIcon sx={{ mr: 1 }} />
                  Aceitar
                </MDButton>

              </MDBox>
            </Tooltip>
          </MDBox>
        </MDBox>
      </MDBox>
    </MDBox>
  )
})

export default TermsOfUse
