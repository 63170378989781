import { lazily } from 'react-lazily'
import mainStore from "./main"

let { userStore, get_401, get_403 } = lazily(() => import("./user"))


export {
    mainStore,
    userStore,
    get_401,
    get_403,
}

