//  Libs
import { action, observable } from "mobx"

// Template
import { navBarStore } from "@cloudmatize/cm-core-template/layout/components_custom"
import { globalSidenavStore } from "@cloudmatize/cm-core-template/layout/demos"

// Project
import { Rest } from "application/rest"
import UserService from "stores/userService"


const doGetResourceApp = async () => {
    const rest = new Rest('setup/resource')
    rest.api = 'ananf/api'
    const response = await rest.list()
    return await response.json()
}

const doGetSchoolUser = async () => {
    const rest = new Rest('userschool/get_school_by_user')
    rest.api = 'ananf/api'
    const response = await rest.list()
    return await response.json()
}

const mainStore = observable({
    loading: false,
    message: null,
    services: {},
    getResourceApp: async function () {
        const that = this
        that.loading = true
        await doGetResourceApp().then(response => {
            if (response?.global_menu_items?.length > 0) {
                globalSidenavStore.global_routes = response.global_menu_items
                globalSidenavStore.show_global_sidenav = true
            } else {
                globalSidenavStore.show_global_sidenav = false
            }
            that.services = response.services
        }).catch(error => {
            console.error(error)
            that.message = {
                content: error.detail || "Houve algum erro na busca pelos serviços!",
                severity: "error"
            }
        }).finally(() => {
            that.loading = false
        })
    },
    getProfileNameUser: async function () {
        this.loading = true
        navBarStore.profile_name = UserService.getListRolesUserKeycloak()
        if (navBarStore.profile_name?.includes(process.env.REACT_APP_PROFILE_SCHOOL)) {
            await doGetSchoolUser().then(response => {
                navBarStore.additional_information = response.map(school => school.name).join(", ")
            })
            if (navBarStore.additional_information === "") {
                navBarStore.additional_information = "Sem escola associada"
            }
        }
        this.loading = false
    },
    setAdditionalInformation: function (text = null) {
        navBarStore.additional_information = text
    }
}, {
    getGlobalRoutes: action,
})

export default mainStore