// Libs
import { configure } from 'mobx'
import { StrictMode } from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'

// Template
import { MaterialUIControllerProvider } from '@cloudmatize/cm-core-template/layout/context'

// Project
import { App } from 'App'
import UserService from 'stores/userService'

configure({
  enforceActions: 'never'
})

console.log('REACT_APP_REST_API=', process.env.REACT_APP_REST_API)

const renderApp = () =>
  ReactDOM.render(
    <BrowserRouter>
      <MaterialUIControllerProvider>
        <StrictMode>
          <App />
        </StrictMode>
      </MaterialUIControllerProvider>
    </BrowserRouter>,
    document.getElementById('root')
  ) || undefined

UserService.initKeycloak(renderApp)
