// Libs
import { Card } from '@mui/material'

// Template
import {
  MDBox,
  MDTypography
} from '@cloudmatize/cm-core-template/layout/components'

const Home = () => {
  return (
    <Card>
      <MDBox py={3}>
        <MDTypography variant="h6" textAlign="center">
          Portal de controle de ANANFs
        </MDTypography>
      </MDBox>
    </Card>
  )
}

export default Home
